import { v4 as uuidv4 } from "uuid";
import { NavLink } from "react-router-dom";

import contentLib from "../lib/contentLib";

import classes from "./TagList.module.css";

function createNavLink(t) {
  let tag = t;
  let count = null;
  if (typeof t !== "string") {
    tag = t.tag;
    count = t.count;
  }

  return (
    <li key={uuidv4()}>
      <NavLink
        to={
          tag === "All"
            ? `/posts`
            : {
                pathname: `/tags/${tag.replace(/[ /]/g, "_").toLowerCase()}`,
              }
        }
      >
        {tag === "All" ? "All" : contentLib.getTagDisplayName(tag)}
        {count ? <span className={classes.count}>{count}</span> : null}
      </NavLink>
    </li>
  );
}

function TagList({ tags }) {
  return (
    <div className={classes.TagList}>
      <ul>{tags?.map((t) => createNavLink(t))}</ul>
    </div>
  );
}

export default TagList;
