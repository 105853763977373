const tagMap = {
  docker: "Docker",
  "soft-skills": "Soft Skills",
  politics: "Politics",
  "gitlab-ci-cd": "Gitlab CI/CD",
  "ci-cd": "CI/CD",
  postgresql: "PostgreSQL",
};

export default tagMap;
