import contentMetaData from "./contentMetaData";
import tagMap from "./tagMap";

function getTags() {
  let tagCounts = [{ tag: "All", count: 0 }];
  contentMetaData.forEach((c) => {
    tagCounts[0].count++;
    c.tags.forEach((t) => {
      let i = tagCounts.findIndex((tc) => tc.tag === t);
      i === -1 ? tagCounts.push({ tag: t, count: 1 }) : tagCounts[i].count++;
    });
  });

  return tagCounts;
}

function getTagDisplayName(tag) {
  return tagMap[tag];
}

async function getPostByLink(link) {
  let post = contentMetaData.find((c) => c.link === link);

  let file = await fetch(`/posts/${post.filename}`);
  post.markdown = await file.text();
  if (post.markdown.includes("<!DOCTYPE html>")) {
    post.markdown = null;
  }

  return {
    meta: { title: post.title, date: post.date, tags: post.tags },
    markdown: post.markdown,
  };
}

async function getPostsMetaDataByTag(tag = null) {
  if (!tag) {
    return contentMetaData;
  }

  return contentMetaData.filter((c) => c.tags.contains(tag));
}

const contentLib = {
  getTags: getTags,
  getTagDisplayName: getTagDisplayName,
  getPostByLink: getPostByLink,
  getPostsMetaDataByTag: getPostsMetaDataByTag,
};

export default contentLib;
