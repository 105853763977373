const contentMetaData = [
  // {
  //   title: "Setting up PostgreSQL with Docker",
  //   link: "setup-postgresql-docker",
  //   createdDate: "June 24, 2023",
  //   filename: "setup-postgresql-using-docker.md",
  //   tags: ["docker", "postgresql"],
  // },
  // {
  //   title: "How to Create Buy In",
  //   link: "creating-buy-in",
  //   createdDate: "June 3, 2023",
  //   filename: "creating-buy-in.md",
  //   tags: ["politics", "soft-skills"],
  // },
  // {
  //   title: "Continuous Delivery Pipleines",
  //   link: "continuous-delivery-pipelines",
  //   createdDate: "Apr 18, 2023",
  //   filename: "continuous-delivery-pipelines.md",
  //   tags: ["ci-cd"],
  // },
  // {
  //   title: "Working with Gitlab CI/CD",
  //   link: "working-with-gitlab-ci-cd",
  //   createdDate: "Apr 1, 2023",
  //   filename: "working-with-gitlab-ci.md",
  //   tags: ["gitlab-ci-cd"],
  // },
  // {
  //   title: "Finding a Sponsor",
  //   link: "finding-a-sponsor",
  //   createdDate: "Mar 24, 2023",
  //   filename: "finding-a-sponsor.md",
  //   tags: ["politics"],
  // },
];

export default contentMetaData;
