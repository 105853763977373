import { NavLink } from "react-router-dom";

import classes from "./PostList.module.css";
import contentMetaData from "../lib/contentMetaData";

function PostList({ tag }) {
  return (
    <div className={classes.List}>
      <ul>
        {contentMetaData?.map((c) =>
          !tag || c.tags.includes(tag) ? (
            <li key={c.link}>
              <NavLink to={`/posts/${c.link}`} end>
                <span className={classes.title}>{c.title}</span>
                <span className={classes.date}>{c.createdDate}</span>
              </NavLink>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
}

export default PostList;
