import classes from "./HomeHeader.module.css";

function HomeHeader() {
  return (
    <div className={classes.HomeHeader}>
      <h1>rct.fyi</h1>
      <p>Sharing what I know about software</p>
    </div>
  );
}

export default HomeHeader;
