import { RouterProvider, createBrowserRouter } from "react-router-dom";

import EditEventPage from "./pages/EditEvent";
import ErrorPage from "./pages/Error";
import EventDetailPage, {
  loader as eventDetailLoader,
  action as deleteEventAction,
} from "./pages/EventDetail";
import EventsPage, { loader as eventsLoader } from "./pages/Events";
import EventsRootLayout from "./pages/EventsRoot";
import HomePage from "./pages/Home";
import NewEventPage from "./pages/NewEvent";
import RootLayout from "./pages/Root";
import { action as manipulateEventAction } from "./components/EventForm";
import NewsletterPage, { action as newsletterAction } from "./pages/Newsletter";
import PostsPage from "./pages/Posts";
import PostPage from "./pages/Post";
import TagsPage from "./pages/Tags";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      // {
      //   path: "events",
      //   element: <EventsRootLayout />,
      //   children: [
      //     {
      //       index: true,
      //       element: <EventsPage />,
      //       loader: eventsLoader,
      //     },
      //     {
      //       path: ":eventId",
      //       id: "event-detail",
      //       loader: eventDetailLoader,
      //       children: [
      //         {
      //           index: true,
      //           element: <EventDetailPage />,
      //           action: deleteEventAction,
      //         },
      //         {
      //           path: "edit",
      //           element: <EditEventPage />,
      //           action: manipulateEventAction,
      //         },
      //       ],
      //     },
      //     {
      //       path: "new",
      //       element: <NewEventPage />,
      //       action: manipulateEventAction,
      //     },
      //   ],
      // },
      // {
      //   path: "posts",
      //   children: [
      //     {
      //       index: true,
      //       element: <PostsPage />,
      //       // loader: eventsLoader,
      //     },
      //     {
      //       path: ":postLink",
      //       element: <PostPage />,
      //       // loader: eventDetailLoader,
      //     },
      //   ],
      // },
      // {
      //   path: "tags",
      //   children: [
      //     {
      //       index: true,
      //       element: <TagsPage />,
      //     },
      //     {
      //       path: ":tag",
      //       children: [
      //         {
      //           index: true,
      //           element: <PostsPage />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "newsletter",
      //   element: <NewsletterPage />,
      //   action: newsletterAction,
      // },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
