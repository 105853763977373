import PostList from "../components/PostList";
import HomeHeader from "../components/HomeHeader";
import PageContent from "../components/PageContent";

function HomePage() {
  return (
    <PageContent>
      <HomeHeader />
      <PostList />
    </PageContent>
  );
}

export default HomePage;
