import PageContent from "../components/PageContent";
import TagList from "../components/TagList";
// import tags from "../lib/tags";

import contentLib from "../lib/contentLib";

function TagsPage() {
  let tags = contentLib.getTags();

  return (
    <>
      <PageContent title="Explore Tags" subtitle="Explore posts by tag">
        <TagList tags={tags} />
      </PageContent>
    </>
  );
}

export default TagsPage;
