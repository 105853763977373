import classes from "./PageContent.module.css";
import TagList from "./TagList";

function PageContent({ tags, title, subtitle, date, children }) {
  let header = null;

  if (tags || title || subtitle || date) {
    header = (
      <div className={classes.PageContent}>
        <TagList tags={tags} />
        {title ? <h1>{title}</h1> : null}
        {subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
        {date ? <div className={classes.subtitle}>{date}</div> : null}
      </div>
    );
  }

  return (
    <div className="container">
      {header}
      {children}
    </div>
  );
}

export default PageContent;
